<template>
  <div v-if="getErrorMessage" class="text-red text-body-2">
    <p>{{ getErrorMessage }}</p>
  </div>
</template>

<script setup lang="ts">
import {defineProps, computed} from 'vue';

const props = defineProps({
  errors: {
    type: Object,
  },
  name: {
    type: String,
  }
})


const getErrorMessage = computed(() => {
  if (!props.errors) return false;

  if (typeof props.errors !== 'object') return false;

  if (props.name in props.errors === false) return false;

  return props.errors[props.name][0];
})
</script>
