const filterValues = [
    {
        name: 'created_at',
        label: 'Date'
    },
    {
        name: 'name',
        label: 'Name'
    },
    {
        name: 'description',
        label: 'Description'
    },
]

const sortValues = [
    {
        name: 'asc',
        label: 'Asc'
    },
    {
        name: 'desc',
        label: 'Desc'
    }
]

const timeframeUnit = [
    {
        name: 'select',
        label: 'Select'
    },
    {
        name: 'hours',
        label: 'Hours'
    },
    {
        name: 'days',
        label: 'Days'
    },
    {
        name: 'weeks',
        label: 'Weeks'
    },
    {
        name: 'months',
        label: 'Months'
    }
];

export {filterValues, sortValues, timeframeUnit}