<template>
  <v-container class="px-0 pb-5 surveys" style="background-color:#F0F0F0; position: relative;">
    <v-card-title class="date-label mx-3">
      Entry: <span class="date-color">{{ getSurveyCompletedDate }}</span>
    </v-card-title>

    <v-container
        v-for="(question, index) in state.survey" :key="`survey-${index}`"
        class="px-6 py-0"
    >
      <v-sheet class="border-rounded pa-3 mb-3">
        <div class="pa-2">
          <p class="mb-3">{{ question?.questions?.title }}</p>
          <RatingSlider
              v-model="question.answer"
              :color="settingsStore.settings.primaryColor"
              readonly
          />
        </div>
      </v-sheet>

      <v-textarea
          v-if="question?.questions?.enable_comments == 1"
          :value="question.comment"
          class="disable-pointer-events"
          density="compact"
          no-resize
          placeholder="Add a Comment..."
          rows="1"
          variant="solo"
      />
    </v-container>

  </v-container>
</template>

<script lang="ts" setup>
import moment from "moment/moment";
import {computed, reactive} from "vue";
import {useSettingsStore} from "@/api/store/settings";
import {useRouter} from "vue-router";
import {surveysService} from "@/api/services/surveys";
import RatingSlider from "@/components/custom/RatingSlider.vue";

const settingsStore = useSettingsStore();
const router = useRouter();

const state = reactive({
  survey: [] as any[],
})
const getQuestionsResponse = () => {
  settingsStore.loading = true;

  surveysService.getQuestionsResponse(router.currentRoute.value.params.id as number).then((response) => {

    state.survey = [...response];
    router.currentRoute.value.meta.showTitleBar = true;
    router.currentRoute.value.meta.title = router.currentRoute.value.params.surveyName;

    settingsStore.loading = false;
  });
}

const getSurveyCompletedDate = computed(() => {
  if (state.survey[0] === undefined) return;

  return moment(state.survey[0].client_survey.completed).format('DD MMM YYYY')
})


getQuestionsResponse()
</script>

<style lang="scss" scoped>
:deep(.v-field--variant-solo) {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #0000001A;
  color: #838383;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}

:deep(.v-field__input) {
  padding: 20px;
}
</style>