<template>
    <v-container>
        <v-row>
            <v-col class="w-50">
                <div id="dropin-container"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { reactive, defineProps, defineEmits, onMounted } from 'vue';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

const emits = defineEmits(['payment:success', 'payment:error'])
const props = defineProps({
  session: Object,
});

const state = reactive({
    configuration: {
        environment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
        clientKey: process.env.VUE_APP_ADYEN_CLIENT_KEY,
        analytics: {
            enabled: false // Set to false to not send analytics data to Adyen.
        },
        session: props.session,
        onPaymentCompleted: (result: any, component: any) => {
            emits('payment:success', result);
        },
        onError: (error: any, component: any) => {
            emits('payment:error', error);
        },
        paymentMethodsConfiguration: {
            card: {
                hasHolderName: true,
                holderNameRequired: true,
                billingAddressRequired: false,

                // styles: {
                //     base: {
                //         color: "#ffffff"
                //     },
                //     error: {},
                //     placeholder: {},
                //     validated: {}
                // }
            }
        }
    }
});

const initialiseAdyen = async() => {
    const checkout = await AdyenCheckout(state.configuration);
    const dropinComponent = checkout.create('dropin').mount('#dropin-container');
};

onMounted(() => {
    initialiseAdyen();
});
</script>

<style lang="scss">
#dropin-container {
    .adyen-checkout__label__text
    .adyen-checkout__fieldset__title,
    .adyen-checkout__button__text {
        color: #7c8098;
        font-family: "Roboto", Helvetica, Arial, sans-serif;
    }

    .adyen-checkout__payment-method__header {
        color: #1EBCD5;
        text-transform: uppercase;
    }

    .adyen-checkout__button--pay,
    .adyen-checkout__button--pay:hover {
        color: #fff;
        background-color: #70e591;
    }

    .adyen-checkout__button__text {
        color: #fff;
    }

    ul li input[type=text] {
    display: block !important;
    }
}
</style>
