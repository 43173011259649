<template>
  <div class="form-input w-100" :class="item.position ? 'text-' + props.item.position : ''">
    <img :src="getImage" alt="Image" :height="item.height"/>
  </div>
</template>

<script setup>
import {reactive, watch, defineProps, computed} from 'vue';
import {useUserStore} from "@/api/store/user";

const userStore = useUserStore();

const props = defineProps({
  item: Object,
});

const getImage = computed(() => {
  if (props.item.source === 'logo') {
    return userStore?.user?.file?.file_path
  }

  return props.item.content;
})
</script>