<template>
  <section v-if="!isShipping" class="order-card-item-details">
    <v-sheet v-if="getImage" class="logo-container m-0">
      <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-sheet v-else :class="{'secondary-background-button-color': supplierId === 'clinic'}" class="icon-container">
      <Icon :icon="getIcon"/>
    </v-sheet>

    <v-card-text class="card-description">
      <section>
        <p class="font-size-12-600">{{ getTitle }}</p>
        <p class="font-size-10-400 mt-2">{{ getBrandName }}</p>
        <p class="font-size-10-400 mt-2 font-grey-dark">{{ getSku }}</p>
        <p class="font-size-10-400 mt-2 font-grey-dark">
          Qty: <span class="font-black">{{ getQuantity }}
        </span></p>
      </section>
      <section class="text-right font-size-16-600">
        <p>{{ getPrice }}</p>
        <p :class="paymentStatus.color" class="font-size-12-600">{{ paymentStatus.status }}</p>
      </section>

      <div v-if="getProductDescription" class="mt-3 full-width">
        <div class="d-flex ga-2">
          <div>
            <Icon icon="message-icon"></Icon>
          </div>
          <div class="two-line-ellipsis font font-grey-dark">
            <div v-html="getAttributeValue('comments')"></div>
          </div>
        </div>
        <div class="text-right">
          <v-btn
              :ripple="false"
              class="primary-underline-button text-capitalize font-size-12-600 pa-0"
              variant="plain"
              @click="toggleText"
          >
            Show More
          </v-btn>
        </div>
        <OrderDetailsDialog
            v-model="showMore"
            :attributes="getAttributes"
        />
      </div>
    </v-card-text>
  </section>
</template>

<script lang="ts" setup>
import {defineProps, ref, computed} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import OrderDetailsDialog from "@/views/OrdersComponents/OrderDetails/ProductDetails/OrderDetailsDialog.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  paymentStatus: {
    type: Object,
    default: () => ({color: '', status: ''})
  },
  isShipping: {
    type: Boolean,
    default: false
  },
  supplierId: {
    type: String,
  }
});

const showMore = ref(false);

const toggleText = () => {
  showMore.value = !showMore.value;
};

const getTitle = computed(() => {
  if (props.item?.product) {
    return props.item.product.name;
  }

  return props.item.name;
})

const getImage = computed(() => {
  if (props.item?.product && props.item?.product?.images[0]) {
    return props.item?.product?.images[0].full;
  }

  return null;
})

const getIcon = computed(() => {
  const type = props.item.type;

  if (type === 'clinic_product' || type === 'supplement' || type === 'product') {
    return 'product-icon';
  }

  return null;
})

const getPrice = computed(() => {
  return props.item.price_display ?? '-';
})

const getBrandName = computed(() => {
  if (props.item?.product && props.item?.product?.brand) {
    return `(${props.item?.product?.brand?.name})`;
  }

  return '';
})

const getSku = computed(() => {
  if (props.item?.product) {
    return props.item.product.sku;
  }

  return '';
})

const getQuantity = computed(() => {
  return `${props.item.quantity} item`;
})

const getProductDescription = computed(() => {
  return props.item?.product?.attributes.length > 0
});

const getAttributes = computed(() => {
  if (props.item.attributes.length > 0) {
    return props.item?.attributes ?? [];
  }

  return props.item?.product?.attributes ?? [];
})

const getAttributeValue = (name) => {
  if (props.item?.product && props.item.type !== 'clinic_service') {
    return getAttributes.value.find((attribute) => attribute.name == name)?.value;
  }

  return ''
};
</script>

<style lang="scss" scoped>
.order-card-item-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  margin: 18px 0;

  .logo-container {
    width: 126.61px;
    height: 122.57px;

    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .card-description {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 2fr 2fr;
    grid-template-areas: 
    "a b"
    "c c";
    padding: 0;
  }

  .card-description > section:first-child {
    grid-area: a;
  }

  .card-description > section:last-child {
    grid-area: b;
  }

  .card-description .full-width {
    grid-area: c;
  }
}

.font {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width {
  width: 235px;
}

.icon-container {
  border-radius: 5px;
  width: 126.61px;
  height: 122.57px;
  margin: auto;
  background: #3A5485;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>