<template>
  <v-container class="px-0 pt-0 pb-5">
    <div class="text-right px-4 pt-3" @click="router.push({ name: 'health-goals' })">
      <Icon color="primaryColor" icon="cross-icon"/>
    </div>

    <section class="px-10 scrollable-section">
      <CreatedBy/>

      <HealthGoalForm
          v-model="formState"
          :errors="errors"
      />
    </section>
  </v-container>

  <section class="pt-16">
    <ButtonFixed
        :custom-class="['text-white', {
          'primary-button': !disableSave,
          'grey-disable': disableSave || loading
        }]"
        custom-icon-class="add-new-circle-icon"
        text="Save"
        @click="onSave"
    />
  </section>
</template>

<script setup lang="ts">
import ButtonFixed from "@/components/custom/ButtonFixed.vue";
import Icon from "@/components/Icons/Icon.vue";
import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import CreatedBy from "@/components/custom/CreatedBy.vue";
import HealthGoalForm from "@/views/ClientComponents/HealthGoals/Components/HealthGoalForm.vue";
import {healthGoalsService} from "@/api/services/healthGoals";

const router = useRouter();
const loading = ref(false);
const errors = ref(null);

const formState = ref({
  name: '',
  description: '',
  reason: '',
  timeframe_value: '',
  timeframe_unit: 'select',
  severity_rating: 0,
  status: null,
  is_complete: null
});

const disableSave = computed(() => {
  return !formState.value.name || !formState.value.description || !formState.value.reason || !formState.value.timeframe_value || !formState.value.timeframe_unit || !formState.value.severity_rating;
})

const onSave = () => {
  loading.value = true;
  healthGoalsService.create(formState.value)
      .then(() => {
        loading.value = false;
        router.push({name: 'health-goals'});
      })
      .catch((error) => {
        console.log(error);
        errors.value = error.response.data.errors
        loading.value = false;
      });
}
</script>

<style lang="scss" scoped>
.scrollable-section {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  padding-bottom: 16px;
}
</style>