<template>
    <div v-if="item.type === 'section'" class="section-container">
        <div class="d-flex justify-space-between align-center cursor-pointer section-header text-capitalize" @click="toggleSection">
            <div><h3>{{ item.label }}</h3></div>
            <div :class="isExpanded ? 'rotate-270' :'rotate-90'">
              <Icon  icon="chevron-icon" color="#7F898E"></Icon>
            </div>
        </div>
        <v-expand-transition>
            <div v-show="isExpanded" class="mt-2 section-content">
                <slot></slot>
            </div>
        </v-expand-transition>
    </div>
    <div v-else>
        <component :is="fieldComponent" :item="item" 
        :modelValue="modelValue"
        @update:modelValue="updateValue"></component>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { computed, defineProps, defineEmits } from 'vue';

import DateField from './fields/DateField.vue';
import AutoText from './fields/AutoText.vue';
import CheckboxField from './fields/CheckboxField.vue';
import DividerField from './fields/DividerField.vue';
import ProductsTable from './fields/ProductsTable.vue';
import SelectField from './fields/SelectField.vue';
import SwitchField from './fields/SwitchField.vue';
import TextArea from './fields/TextArea.vue';
import TextField from './fields/TextField.vue';
import RadioField from './fields/RadioField.vue';
import ScaleField from './fields/ScaleField.vue';
import ImageField from './fields/ImageField.vue';
import ListField from './fields/ListField.vue';
import Icon from "@/components/Icons/Icon.vue";

const props = defineProps({
    item: Object,
    modelValue: [String, Number, Boolean, Array, Object]
});

const emit = defineEmits(['update:modelValue']);

const isExpanded = ref(true);
const toggleSection = () => {
    isExpanded.value = !isExpanded.value;
};

const fieldComponents = {
    date: DateField,
    text: AutoText,
    checkbox: CheckboxField,
    divider: DividerField,
    image: ImageField,
    list: ListField,
    products: ProductsTable,
    radio: RadioField,
    select: SelectField,
    scale: ScaleField,
    switch: SwitchField,
    textarea: TextArea,
    textbox: TextField
};

const fieldComponent = computed(() => fieldComponents[props.item.type] || 'div');

const updateValue = (value) => {
    emit('update:modelValue', value);
};
</script>

<style scoped>
.section-container {
  padding: 8px 0;
  
  .section-header {
    background: #F5F5F5;
    padding: 10px 16px;
    border-radius: 10px;
  }
}

.section-content {
    padding: 8px;
}

.rotate-270 {
    transform: rotate(270deg);
}

.rotate-90 {
    transform: rotate(90deg);
}
</style>
