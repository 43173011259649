<template>
  <v-container class="px-0 pt-0 pb-5">
    <div class="text-right px-4 pt-3" @click="router.push({ name: 'health-goals' })">
      <Icon color="primaryColor" icon="cross-icon"/>
    </div>

    <section class="px-10 scrollable-section">
      <CreatedBy/>

      <HealthGoalForm
          v-model="formState"
          :errors="errors"
      />
    </section>
  </v-container>

  <section class="pt-16 position-relative">
    <div class="buttons-container">
      <div class="d-flex align-center my-3 ga-2">
        <ButtonBase
            :custom-class="isCompleted ? 'secondary-button' : 'bg-white grey-btn text-black'"
            :text="isCompleted ? 'Completed' : 'Mark Completed'"
            :icon="isCompleted ? 'thick-icon' : ''"
            color-icon="secondary-button-color"
            width="48%"
            @click="onComplete"
        />

        <ButtonBase
            custom-class="white-btn text-white font-size-12-600"
            :text="isActive ? 'Archive' : 'Unarchive'"
            :icon="isActive ? 'archived-icon' : 'unarchived-icon'"
            color-icon="primary-color"
            width="48%"
            @click="onArchive"
        />
      </div>

      <ButtonBase
          :custom-class="['text-white', {
          'primary-button': !disableSave,
          'grey-disable': disableSave || settingsStore.loading
        }]"
          custom-icon-class="add-new-circle-icon"
          text="Save"
          width="100%"
          @click="onSave"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import Icon from "@/components/Icons/Icon.vue";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import CreatedBy from "@/components/custom/CreatedBy.vue";
import HealthGoalForm from "@/views/ClientComponents/HealthGoals/Components/HealthGoalForm.vue";
import {healthGoalsService} from "@/api/services/healthGoals";
import {useSettingsStore} from "@/api/store/settings";
import ButtonBase from "@/components/custom/ButtonBase.vue";

const settingsStore = useSettingsStore();

const router = useRouter();
const route = useRoute();

const errors = ref(null);

const formState = ref({
  name: '',
  description: '',
  reason: '',
  timeframe_value: '',
  timeframe_unit: 'select',
  severity_rating: 0,
  status: null,
  is_complete: null
});

const disableSave = computed(() => {
  return !formState.value.name || !formState.value.description || !formState.value.reason || !formState.value.timeframe_value || !formState.value.timeframe_unit || !formState.value.severity_rating;
})

const isCompleted = computed(() => {
  return formState.value.is_complete === 'complete';
})

const isActive = computed(() => {
  return formState.value.status === 'active';
})

const onSave = () => {
  settingsStore.loading = true;
  healthGoalsService.update(route.params.id, formState.value)
      .then(() => {
        settingsStore.loading = false;
        router.push({name: 'health-goals'});
      })
      .catch((error) => {
        console.log(error);
        errors.value = error.response.data.errors
        settingsStore.loading = false;
      });
}

const fetchHealthGoal = () => {
  healthGoalsService.show(route.params.id).then((response) => {
    formState.value = response.data;
  });
}

const onComplete = () => {
  formState.value.is_complete = isCompleted.value ? 'incomplete' : 'complete';

  if (formState.value.is_complete === 'complete') {
    formState.value.status = 'archived';
  }
}

const onArchive = () => {
  formState.value.status = isActive.value ? 'archived' : 'active';

  if (isActive.value) {
    formState.value.is_complete = null;
  }
}

onMounted(fetchHealthGoal)
</script>

<style lang="scss" scoped>
.scrollable-section {
  overflow-y: auto;
  padding-bottom: 84px;
}

.buttons-container {
  position: fixed;
  bottom: 0;
  background: transparent;
  padding: 18px 23px;
  width: 100%;
  max-width: 480px;
}

.grey-btn {
  border: 1px solid #CED1D6;
  color: #CED1D6 !important;
}
</style>