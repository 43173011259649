import { defineStore } from 'pinia';
import { SettingsData } from '../interfaces/settings';
import { defaultConfig } from '@/api/data/defaultConfig';

const SwandoolaConfig = {
  name: 'Swandoola' as string,
  header: 2 as number,
  layout: 2 as number,
  primaryColor: '#1EBCD5' as string,
  secondaryColor: '#70E591' as string,
  buttonColor: '#C671C2' as string,
  buttonColor2: '#70E591' as string,
  backgroundColor1: 'rgba(30, 188, 213, 0.2)' as string,
  backgroundColor2: 'rgba(30, 188, 213, 0)' as string,
  brandingImg: '/img/swandoola_green.png' as string,
  wellnessImage1: '/img/wellnessitem1.png' as string,
  wellnessImage2: '/img/wellnessitem2.png' as string,
  features: {
    appointments: true as boolean,
    shop: true as boolean,
    blog: true as boolean,
  },
  shopFeaturedItems: true as boolean,
  visibleNaturalDispensaryInApp: false,
};

const EmbracingNutritionConfig = {};
const GreaterByNatureConfig = {};

export const useSettingsStore = defineStore('settings', {
  state: () => {
    return {
      set: false as boolean,
      loading: false as boolean,
      settings: SwandoolaConfig,
      error: false as boolean,
      error_message: '' as string,
    }
  },
  actions: {
    showError(message: string): void {
      this.error = true;
      this.error_message = message;

      setTimeout(() => {
        this.error = false;
      }, 3000);
      
    }
  }
})

/*Swandoola
{
	"header": 2,
	"layout": 2,
	"features": {
		"blog": true,
		"shop": true,
		"appointments": true
	},
	"brandingImg": "/img/swandoola_green.png",
	"primaryColor": "#1EBCD5",
	"secondaryColor": "#70E591",
	"wellnessImage1": "/img/wellnessitem1.png",
	"wellnessImage2": "/img/wellnessitem2.png",
	"backgroundColor1": "rgba(30, 188, 213, 0.2)",
	"backgroundColor2": "rgba(30, 188, 213, 0)",
	"shopFeaturedItems": [
		{
			"id": 1,
			"img": "/img/productExample1.png",
			"sku": "NTN-CDQZ120",
			"name": "CDQZ Immune Support 120's",
			"brand": "Not the Norm",
			"price": 15
		},
		{
			"id": 2,
			"img": "/img/productExample2.png",
			"sku": "AQS-165450",
			"name": "Dandelion Coffee Instant 50g",
			"brand": "AquaSol",
			"price": 23
		}
	]
}
*/
/*
settings: {
        header: 2 as number,
        layout: 2 as number,
        primaryColor: '#F4D8CD' as string,
        secondaryColor: '#F4D8CD' as string,
        backgroundColor: '#BFDBD64D' as string,
        brandingImg: '/img/gbn-logo.webp' as string,
        wellnessImage1: '/img/wellnessitem3.png' as string,
        wellnessImage2: '/img/wellnessitem4.png' as string,
        features: {
          appointments: true as boolean,
          shop: true as boolean,
          blog: true as boolean,
        },
        shopFeaturedItems: [
          {
            id: 1,
            name: 'CDQZ Immune Support 120\'s',
            img: '/img/productExample1.png',
            price: 15.00 as number,
            brand: 'Not the Norm',
            sku: 'NTN-CDQZ120',
          },
          {
            id: 2,
            name: 'Dandelion Coffee Instant 50g',
            img: '/img/productExample2.png',
            price: 23.00 as number,
            brand: 'AquaSol',
            sku: 'AQS-165450',
          }
        ]
        */