<template>
  <v-text-field
      placeholder="Name"
      density="compact"
      class="pt-3 pb-5"
      width="100%"
      variant="solo"
      elevation="0"
      hide-details
      v-model="setForm.name"
  />

  <ErrorMessages :errors="errors" name="name"/>

  <div class="pb-6">
    <p class="font-size-12-400 font-grey pb-2">Description</p>
    <TextArea v-model="setForm.description" rows="3"/>
  </div>

  <ErrorMessages :errors="errors" name="description"/>

  <div class="pb-6">
    <p class="font-size-12-400 font-grey pb-2">Why Does This Matter?</p>
    <TextArea v-model="setForm.reason" rows="3"/>
  </div>

  <ErrorMessages :errors="errors" name="reason"/>

  <FlexContainer title="Timeframe">
    <div class="v-col-6 pr-0">
      <div class="d-flex align-center justify-end ga-2">
        <input
            class="styled-input"
            v-model="setForm.timeframe_value"
            type="number"
            min="1"
            :disabled="disabledSelect"
            :readonly="readonlySelect"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
        />

        <div style="width: fit-content">
          <SelectDropdown
              v-model="setForm.timeframe_unit"
              :items="timeframeUnit"
              :variant="variant"
              :disabled="disabledSelect"
              :readonly="readonlySelect"
          />
        </div>
      </div>
    </div>
  </FlexContainer>

  <ErrorMessages :errors="errors" name="timeframe_value"/>

  <ErrorMessages :errors="errors" name="timeframe_unit"/>

  <v-divider></v-divider>

  <FlexContainer title="Severity rating" @click="rotateArrowForRating = !rotateArrowForRating">
    <div class="d-flex align-center ga-4 py-2">
      <p class="font-size-12-600 font-black">{{ setForm.severity_rating }}/10</p>
      <RotatingArrow :rotate-arrow="rotateArrowForRating"/>
    </div>
  </FlexContainer>

  <ErrorMessages :errors="errors" name="severity_rating"/>

  <v-divider></v-divider>

  <div v-if="rotateArrowForRating === true" class="my-3">
    <RatingSlider v-model="setForm.severity_rating"/>
  </div>
</template>

<script setup lang="ts">
import {defineProps, ref, defineEmits, watch} from 'vue';
import TextArea from "@/components/custom/TextArea.vue";
import SelectDropdown from "@/components/custom/SelectDropdown.vue";
import FlexContainer from "@/components/custom/FlexContainer.vue";
import ErrorMessages from "@/components/custom/ErrorMessages.vue";
import {timeframeUnit} from "../Helpers.ts";
import {useRoute} from "vue-router";
import RatingSlider from "@/components/custom/RatingSlider.vue";
import RotatingArrow from "@/components/custom/RotatingArrow.vue";

const route = useRoute();

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Object,
  },

  variant: {
    type: String,
  },

  disabledSelect: {
    type: Boolean,
    default: false,
  },

  readonlySelect: {
    type: Boolean,
    default: false,
  },

  errors: {
    type: Object,
  }
})

const setForm = ref({...props.modelValue});
const rotateArrowForRating = ref(false);

watch(() => props.modelValue, (val) => {
  setForm.value = val
})

watch(setForm, (newValue) => {
  if (newValue.severity_rating > 0) {
    rotateArrowForRating.value = true;
  }

  emit('update:modelValue', newValue);
}, {deep: true});
</script>

<style scoped lang="scss">
:deep(.v-field--variant-solo, .v-field--variant-solo-filled) {
  box-shadow: none;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

:deep(.v-select__selection) {
  background: #F5F5F5;
  padding: 8px 16px;
  border-radius: 10px;
}

.styled-input {
  width: 60px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  background-color: #f5f5f5;
  color: #000;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.styled-input:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>