<template>
  <v-container v-if="settingsStore.loading === false" class="px-0 pt-0 pb-5">
    <div class="text-right px-4 pt-3" @click="router.push({ name: 'health-goals' })">
      <Icon color="primaryColor" icon="cross-icon"/>
    </div>

    <section class="px-10">
      <CreatedBy icon="practitioner-icon"/>

      <h2 class="font-size-18-600 mt-3">{{ formState.name }}</h2>

      <div class="mt-3">
        <p class="font-size-12-400 font-grey ">Description</p>
        <p class="font-size-14-400 ">{{ formState.description }}</p>
      </div>

      <div class="mt-3">
        <p class="font-size-12-400 font-grey ">Why Does This Matter?</p>
        <p class="font-size-14-400">{{ formState.reason }}</p>
      </div>

      <div class="font-size-12-400 font-grey d-flex align-center card-container justify-space-between my-3">
        <p>Timeframe</p>
        <div class="d-flex justify-space-between align-center ga-2 font-size-12-600 font-black">
          <p>{{ formState.timeframe_value }}</p>
          <p>{{ formState.timeframe_unit }}</p>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="font-size-12-400 font-grey d-flex align-center card-container justify-space-between my-3">
        <p>Severity rating</p>
        <p class="font-size-12-600 font-black">{{ formState.severity_rating }}</p>
      </div>

      <v-divider></v-divider>

      <div class="my-3">
        <RatingSlider disable v-model="formState.severity_rating"/>
      </div>

      <div class="d-flex align-center my-3 ga-2 pt-16">
        <ButtonBase
            :custom-class="isCompleted ? 'secondary-button' : 'bg-white grey-btn text-black'"
            :text="isCompleted ? 'Completed' : 'Incomplete'"
            :icon="isCompleted ? 'thick-icon' : ''"
            color-icon="secondary-button-color"
            disabled
            width="48%"
        />

        <ButtonBase
            custom-class="white-btn text-white font-size-12-600"
            :text="isActive ? 'Archived' : 'Active'"
            :icon="isActive ? 'archived-icon' : 'unarchived-icon'"
            color-icon="primary-color"
            disabled
            width="48%"
        />
      </div>
    </section>
  </v-container>
</template>

<script setup lang="ts">
import Icon from "@/components/Icons/Icon.vue";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import CreatedBy from "@/components/custom/CreatedBy.vue";
import {healthGoalsService} from "@/api/services/healthGoals";
import RatingSlider from "@/components/custom/RatingSlider.vue";
import {useSettingsStore} from "@/api/store/settings";
import ButtonBase from "@/components/custom/ButtonBase.vue";

const settingsStore = useSettingsStore();

const router = useRouter();
const route = useRoute();

const formState = ref({
  name: '',
  description: '',
  reason: '',
  timeframe_value: '',
  timeframe_unit: 'select',
  severity_rating: 0,
  client_visibility: true,
});

const isCompleted = computed(() => {
  return formState.value.is_complete === 'complete';
})

const isActive = computed(() => {
  return formState.value.status === 'active';
})

const fetchHealthGoal = () => {
  settingsStore.loading = true;
  healthGoalsService.show(route.params.id).then((response) => {
    formState.value = response.data;
    settingsStore.loading = false;
  });
}

onMounted(fetchHealthGoal)
</script>

<style lang="scss" scoped>
:deep(.v-slider.v-input--horizontal) {
  margin: 0;
}

.grey-btn {
  border: 1px solid #CED1D6;
  color: #CED1D6 !important;
}

:deep(.v-btn--disabled) {
  opacity: 0.8;
}
</style>