<template>
  <v-container class="px-0 pt-0 pb-5" style="background-color:#EEE;min-height:500px;">
    <v-sheet class="d-flex justify-center pa-4 text-center ga-2" style="background: none">
      <div class="flex-grow-1 d-flex justify-end">
        <v-sheet :style="buttonStyle" class="pa-3 b-round-20 position-relative"
                 width="100" @click="router.push({name: 'document-documents' })">
          <v-icon class="my-2" icon="mdi mdi-file-document-outline" size="40"></v-icon>
          <p class="p-text-md">Documents</p>
          <p v-if="state.stats?.total_documents" class="position-absolute badge">
            {{ state.stats?.total_documents }}
          </p>
        </v-sheet>
      </div>
      <div class="flex-grow-1 d-flex justify-center">
        <v-sheet :style="buttonStyle" class="pa-3 b-round-20 position-relative"
                 width="100" @click="router.push({name: 'document-forms'})">
          <v-icon class="my-2" icon="mdi mdi-file-document-edit-outline" size="40"></v-icon>
          <p class="p-text-md">Forms</p>
          <p v-if="state.stats?.total_forms" class="position-absolute badge">
            {{ state.stats?.total_forms }}
          </p>
        </v-sheet>
      </div>
      <div class="flex-grow-1 d-flex justify-start">
        <v-sheet :style="buttonStyle" class="pa-3 b-round-20 position-relative"
                 width="100" @click="router.push({name: 'document-surveys'})">
          <v-icon class="my-2" icon="mdi mdi-chart-box-outline" size="40"></v-icon>
          <p class="p-text-md">Surveys</p>
          <p v-if="state.stats?.total_surveys" class="position-absolute badge">
            {{ state.stats?.total_surveys }}
          </p>
        </v-sheet>
      </div>
    </v-sheet>

    <v-card v-if="Object.keys(groupedActivities).length > 0" class="mt-2 mx-6 mb-2 padding border-rounded">
      <v-card-title class="font-size-18-600 px-0">Recent Activities</v-card-title>

      <div v-for="(activities, date) in groupedActivities" :key="date">
        <v-row class="py-2">
          <v-col cols="12" class="px-3 py-1">
            <p class="font-size-10-600 grey-color">{{ date }}</p>
            <v-divider class="mt-3"></v-divider>
          </v-col>

          <v-col cols="12" class="pa-0" v-for="activity in activities" :key="activity.id">
            <v-list-item>
              <v-list-item-content class="font-size-12-400 d-flex align-center ga-2">
                <div>
                  <Icon :icon="getFileType(activity)" :color="settingsStore.settings.primaryColor"></Icon>
                </div>
                <p>
                  {{ getFile(activity).type }} <span class="font-size-12-600">'{{getFile(activity).title}}'</span> {{getFile(activity).action}}
                </p>
              </v-list-item-content>
              <v-divider class="mt-2"></v-divider>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-card v-else class="mx-4 mt-2">
      <v-card-title class="text-h6">Recent Activities</v-card-title>
      <v-card-text>
        <p class="p-text">No recent activities.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, onMounted, reactive} from 'vue';

import {useSettingsStore} from '@/api/store/settings';
import {useRouter} from 'vue-router';

import {filesService} from '@/api/services/file';
import {filesFeedService} from '@/api/services/filesFeed';
import moment from 'moment';
import Icon from "@/components/Icons/Icon.vue";

const router = useRouter();

const settingsStore = useSettingsStore();

const state = reactive({
  activities: [] as any[],
  search: "" as string,
  sort_asc: true as boolean,
  page: 1 as number,
  tab: "home" as string,
  stats: {} as any,
});

const buttonStyle = computed(() => {
  return "background: white;box-shadow: 4px 4px 10px 0px #0000000D; color: " + settingsStore.settings.primaryColor + ";";
});

const getStats = () => {
  filesService.stats().then((response) => {
    state.stats = response;
  });
}

const getActivities = () => {
  settingsStore.loading = true;
  filesFeedService.index().then((response) => {
    state.activities = response.data;
    settingsStore.loading = false;
  });
}

const groupedActivities = computed(() => {
  const grouped = state.activities.reduce((acc, activity) => {
    const dateKey = moment(activity.created_at).format('D MMM YYYY');
    (acc[dateKey] = acc[dateKey] || []).push(activity);
    return acc;
  }, {});

  return Object.keys(grouped)
      .sort((a, b) => moment(b, 'D MMM YYYY').diff(moment(a, 'D MMM YYYY')))
      .reduce((sortedAcc, key) => {
        sortedAcc[key] = grouped[key];
        return sortedAcc;
      }, {});
})

const getFileType = (type) => {

  if (type.file_resource?.kind === 'form') {
    return 'document-form';
  } else if (type.file_resource) {
    return 'document-file';
  }

  return 'document-survey';
};

const getFile = (type) => {
  if (type.file_resource?.kind === 'form') {
    return {
      type: 'New Form',
      title: type.file_resource.title,
      action:'Shared With You'
    };
  } else if (type.file_resource) {
    return {
      type: 'New Document',
      title: type.file_resource.title,
      action:'Shared With You'
    };
  }

  return {
    type: type.completed === null ? 'New Survey': 'Survey',
    title: type?.survey?.name,
    action: type.completed === null ? 'Added': 'Completed'
  };
};

onMounted(() => {
  getActivities();
  getStats();
});
</script>

<style lang="scss" scoped>
.badge {
  background: linear-gradient(0deg, #EC5151, #EC5151),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  color: #fff;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  position: absolute;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-rounded {
  border-radius: 8px;
  border: 1px solid #E0E4E9;
  box-shadow: none;
}

.padding {
  padding: 20px;
}

.grey-color {
  color: #B5B8BC;
}
</style>