<template>
  <CardComponent>
    <div
        :class="{'primary-color': !isStatusArchived, 'font-grey': isStatusArchived}"
        class="text-center font-size-10-400"
    >
      <Icon :icon="setIconAndColor.icon" :color="setIconAndColor.color"/>
      <p>{{ healthGoal.type }}</p>
    </div>

    <div :class="setColorIfStatusArchived" class="ml-2">
      <div>
        <div class="font-size-14-600 ellipsis">{{ healthGoal.name }}</div>
        <p class="font-size-10-400 ellipsis">{{ healthGoal.description }}</p>
      </div>

      <p class="subtitle mt-2">
        <slot></slot>
      </p>
    </div>

    <div :class="setColorIfStatusArchived" class="text-center">
      <div class="d-flex justify-center mb-4">
        <CreatedBy
            :color="isStatusArchived ? 'grey_light' : 'primaryColor'"
            :gap="setCreateByIcon === 'practitioner-icon'? '0': '4px'"
            :icon="setCreateByIcon"
        />
      </div>

      <v-btn
          :class="{'white-btn': healthGoal.creator !== null || isStatusArchived, 'primary-button': !isStatusArchived}"
          :disabled="disableBtn"
          class="b-round-20 outline-btn"
          elevation="0"
          height="32px"
          x-small
          @click="handleAction"
      >
        {{ setButtonText }}
      </v-btn>
    </div>
  </CardComponent>
</template>

<script lang="ts" setup>
import {computed, defineProps} from 'vue';
import CreatedBy from "@/components/custom/CreatedBy.vue";
import Icon from "@/components/Icons/Icon.vue";
import CardComponent from "@/components/custom/CardComponent.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
  disableBtn: {
    type: Boolean,
    default: false,
  },
  healthGoal: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const isStatusArchived = computed(() => {
  return props.healthGoal?.status === 'archived';
});

const setIconAndColor = computed(() => {

  if (props.healthGoal?.status === 'archived' && props.healthGoal?.is_complete === null) {
    return {
      icon: 'health-goal-active',
      color: 'font-grey'
    }
  }

  if (props.healthGoal?.status === 'archived') {
    return {
      icon: `health-goal-${props.healthGoal.is_complete}`,
      color: 'font-grey'
    }
  }

  return {
    icon: 'health-goal-active',
    color: 'primaryColor'
  }
});

const setCreateByIcon = computed(() => {
  return props.healthGoal.creator === null ? 'client-icon' : 'practitioner-icon'
});

const setColorIfStatusArchived = computed(() => {
  return isStatusArchived.value ? 'font-grey' : 'font-black'
});

const setButtonText = computed(() => {
  return props.healthGoal.creator === null ? 'Edit' : 'View'
});

const handleAction = () => {
  if (props.healthGoal.creator === null) {
    return router.push({
      name: 'edit-health-goals',
      params: {id: props.healthGoal.id},
    });
  }

  return router.push({
    name: 'view-health-goals',
    params: {id: props.healthGoal.id},
  });
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
