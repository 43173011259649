<template>
  <!-- Product Cards -->
  <section class="py-10 border-bottom" v-for="(product, index) in item.content" :key="index">
    <div class="card-item-details">
      <v-sheet v-if="getImage(product)" class="logo-container m-0">
        <div :style="{backgroundImage: `url(${getImage(product)})`}" class="logo-container__image"></div>
      </v-sheet>

      <v-sheet v-else class="icon-container">
        <Icon :class="{'secondary-background-button-color': !product?.supplier }" icon="product-icon"/>
      </v-sheet>

      <v-card-text class="pa-0">
        <h3 class="font-size-12-600">{{ product.name }}</h3>
        <p v-if="product.brand" class="mt-1 font-size-12-400">
          ({{ product.brand ? product.brand.name : product.brand_name }}
          <span class="secondary-button-color">|</span>
          <span class="font-size-12-600">{{ product.price_display }})</span>
        </p>

        <!-- Product Attributes -->
        <div class="font-size-12-400 line-height-18 mt-1"
             v-for="attr in product.attributes"
             :key="attr.name"
        >
          <p v-if="attr.name !== 'comments'">
            <span class="font-grey-dark text-capitalize"> {{ attr.name }}: </span>
            {{ attr.value }}
          </p>
        </div>

        <!-- Supplier Information -->
        <p class="font-size-12-400 mt-1">
          <span class="font-grey-dark text-capitalize">Supplier:</span>
          {{ getSupplierName(product) }}
        </p>
      </v-card-text>
    </div>

    <!-- Additional Comments -->
    <v-card-text class="px-0 pb-0" v-if="getProductComment(product)">
      <div class="font-size-12-400 line-height-18">
        <span class="font-grey-dark"> Comments: </span>
        {{ getProductComment(product) }}
      </div>
    </v-card-text>
  </section>
</template>

<script setup>
import {defineProps} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import {useUserStore} from "@/api/store/user";

const userStore = useUserStore();

const props = defineProps({
  item: Object,
});

const getImage = (product) => {
  return product.images && product.images.length > 0 ? product.images[0].full : null;
};

const getSupplierName = (product) => {
  if (product.supplier) {
    return product?.supplier ?? null;
  }
  return userStore.user?.clinic.name ?? null;
}

const getProductComment = (product) => {
  if (product?.attributes.length === 0) return product?.comments;

  const commentAttribute = product.attributes.find((attr) => attr.name === 'comments');

  return commentAttribute?.value ?? null;
}
</script>

<style lang="scss" scoped>
.card-item-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  .logo-container {
    width: 126px;
    height: 100px;

    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .icon-container {
    border-radius: 5px;
    width: 126px;
    height: 100px;
    background: #3A5485;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.line-height-18 {
  line-height: 18px;
}

.border-bottom {
  border-bottom: 1px solid #E0E4E9
}
</style>
