<template>
  <svg width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_110)">
      <rect width="31" height="34" fill="white"/>
      <path d="M0 -1H16H31V34H0V-1Z" fill="white"/>
      <path d="M0 12H31V30C31 32.2091 29.2091 34 27 34H4C1.79086 34 0 32.2091 0 30V12Z" fill="currentColor"/>
      <path d="M2 12H29V30C29 31.1046 28.1046 32 27 32H4C2.89543 32 2 31.1046 2 30V12Z" fill="white"/>
      <path d="M0 8C0 5.79086 1.79086 4 4 4H27C29.2091 4 31 5.79086 31 8V12H0V8Z" fill="currentColor"/>
      <path d="M2 8C2 6.89543 2.89543 6 4 6H27C28.1046 6 29 6.89543 29 8V10H2V8Z" fill="white"/>
      <rect x="22" y="-1" width="2" height="4" fill="white"/>
      <path d="M7 1C7 0.447715 7.44772 0 8 0V0C8.55228 0 9 0.447715 9 1V4H7V1Z" fill="currentColor"/>
      <path d="M22 1C22 0.447715 22.4477 0 23 0V0C23.5523 0 24 0.447715 24 1V4H22V1Z" fill="currentColor"/>
      <path
          d="M15.5528 28C15.5528 28 11.6186 25.1318 9.65148 22.501C8.63514 20.9185 8.76628 18.6635 10.34 17.4767C11.6711 16.4942 13.5857 17.0283 15.5528 19.0987C17.5199 17.0283 19.4346 16.4942 20.7657 17.4767C22.3394 18.6635 22.4705 20.9185 20.7985 22.501C19.4871 25.1318 15.5528 28 15.5528 28Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <circle cx="30.7778" cy="33.7778" r="7.77783" fill="currentColor"/>
    <path
        d="M30.2717 36.9479L27.7051 34.3813L28.1978 33.8885L30.2717 35.9625L34.6717 31.5625L35.1645 32.0552L30.2717 36.9479Z"
        fill="white" stroke="white"/>
    <circle cx="31.1934" cy="34.1499" r="7.77783" fill="currentColor"/>
    <g clip-path="url(#clip1_1_110)">
      <path
          d="M29.0775 36.965L28.6929 36.5804L30.8083 34.465L28.6929 32.3496L29.0775 31.965L31.1929 34.0804L33.3083 31.965L33.6929 32.3496L31.5775 34.465L33.6929 36.5804L33.3083 36.965L31.1929 34.8496L29.0775 36.965Z"
          fill="white" stroke="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1_110">
        <rect width="31" height="34" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1_110">
        <rect width="7" height="7" fill="white" transform="translate(27.6929 30.6494)"/>
      </clipPath>
    </defs>
  </svg>
</template>
