<template>
  <v-container class="px-0 pt-0 mb-12 position-relative" style="background-color:#F0F0F0; min-height:100vh">
    <v-row class="mt-0 mb-2 py-5 text-center" no-gutters style="background-color:#FFF">
      <v-col cols="12">
        <SearchFilter @update:modelValue="(val: string) => state.search = val"/>
      </v-col>
      <v-col class="d-flex px-3" cols="12">
        <v-col class="mt-1 position-relative left-filter-text" @click="()=>state.filterSelected = 'sort'">
          <ButtonFilterSelect
              v-model="sortTabSelectedValue"
              :is-tab-selected="isSortTabSelected"
              :items="sortValues"
              :multiple="false"
              :text="sortTabSelectedValue"
              icon="sort-icon"
              single-line
          />
        </v-col>
        <v-col class="mt-1 position-relative left-filter-text" @click="()=> state.filterSelected = 'filter'">
          <ButtonFilterSelect
              v-model="filterTabSelectedValue"
              :is-tab-selected="isFilterTabSelected"
              :items="filterValues"
              :multiple="false"
              :text="filterTabSelectedValue"
              single-line
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="text-center py-0" no-gutters>
      <v-col class="d-flex px-4" cols="12">
        <v-col>
          <ButtonBase
              :custom-class="['transparent text-black', {'secondary-button text-white': tabStatusSelected === 'active'}]"
              text="Active"
              width="100%"
              @click="onActiveTab"
          />
        </v-col>
        <v-col>
          <ButtonBase
              :custom-class="['transparent text-black', {'secondary-button text-white': tabStatusSelected === 'archived'}]"
              text="Archived"
              width="100%"
              @click="onArchivedTab"
          />
        </v-col>
      </v-col>
    </v-row>

    <section v-if="!settingsStore.loading">
      <HealthGoalCard
          v-for="healthGoal in state.items"
          :key="healthGoal.id"
          :health-goal="healthGoal"
          class="font-size-12-400"
      >
        <div class="font-grey">{{ healthGoal.timeframe_value }} {{ healthGoal.timeframe_unit }}</div>
      </HealthGoalCard>
    </section>

    <v-card v-if="state.items.length == 0" class="mt-2 mx-2 mb-2 pa-4">
      <v-row class="my-0" no-gutters>
        <v-col cols="12">
          <v-row class="py-2">
            <v-col class="pt-3 px-3">
              <p class="p-text mt-1">No health goals available.</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="state.pagination.links.next" class="text-center">
      <v-btn
          :ripple="false"
          class="load-more text-capitalize font-size-12-600"
          variant="plain"
          @click="seeMore"
      >
        Load More
      </v-btn>
    </div>

    <section v-if="tabStatusSelected === 'active'">
      <ButtonFixed
          color-icon="primaryButtonColor"
          custom-class="text-white primary-button"
          custom-icon-class="add-new-circle-icon"
          icon="add-plus-circle"
          text="Add New"
          @click="router.push({ name: 'create-health-goals' })"
      />
    </section>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive, shallowRef, watch} from 'vue';
import {useSettingsStore} from '@/api/store/settings';
import {useRouter} from 'vue-router';
import {healthGoalsService} from "@/api/services/healthGoals";
import SearchFilter from "@/components/custom/SearchFilter.vue";
import ButtonBase from "@/components/custom/ButtonBase.vue";
import ButtonFixed from "@/components/custom/ButtonFixed.vue";
import {filterValues, sortValues} from "@/views/ClientComponents/HealthGoals/Helpers";
import ButtonFilterSelect from "@/components/custom/ButtonFilterSelect/ButtonFilterSelect.vue";
import debounce from 'lodash/debounce';
import HealthGoalCard from "@/views/ClientComponents/HealthGoals/Components/HealthGoalCard.vue";

const settingsStore = useSettingsStore();

const router = useRouter();

// Data
const state = reactive({
  items: [] as any[],
  pagination: {
    meta: {},
    links: []
  },
  search: '' as string,
  filterSelected: 'filter' as string,
});

const sortTabSelectedValue = shallowRef({
  name: 'Sort',
  label: 'Sort'
})
const filterTabSelectedValue = shallowRef({
  name: 'Filter',
  label: 'Filter'
})
const page = shallowRef(1)

const tabStatusSelected = shallowRef('active');

// Computed
const isFilterTabSelected = computed(() => {
  return state.filterSelected === 'filter'
});

const isSortTabSelected = computed(() => {
  return state.filterSelected === 'sort'
});

const filterQueryString = computed(() => {
  let filter = {
    sorting: '',
    search: state.search,
    tab: tabStatusSelected.value,
    page: page.value
  };

  let sortOrder = sortTabSelectedValue.value.name === 'Sort' ? 'desc' : sortTabSelectedValue.value.name;
  let filterValue = filterTabSelectedValue.value.name === 'Filter' ? 'name' : filterTabSelectedValue.value.name;

  if (filterTabSelectedValue.value.name === 'Filter' && sortTabSelectedValue.value.name === 'Sort') {
    filter.sorting = 'id,desc';
  } else {
    filter.sorting = `${filterValue},${sortOrder}`;
  }

  return filter;
});

// Methods
const getHealthGoals = () => {
  settingsStore.loading = true;
  healthGoalsService.index(filterQueryString.value).then((response) => {
    state.items.push(...response.data)
    state.pagination.meta = response.meta;
    state.pagination.links = response.links;
    settingsStore.loading = false;
  });
}

const resetState = () => {
  page.value = 1;
  state.items = [];
}

const seeMore = () => {
  if (state.pagination.links.next) {
    page.value++;
    getHealthGoals();
  }
}

// Debounced to fetch getHealthGoals
const debouncedHealthGoal = debounce(getHealthGoals, 300);

const onActiveTab = () => {
  tabStatusSelected.value = 'active';
  resetState();
  getHealthGoals();
}
const onArchivedTab = () => {
  tabStatusSelected.value = 'archived';
  resetState();
  getHealthGoals();
}

watch(() => [filterTabSelectedValue.value, sortTabSelectedValue.value, state.search], () => {
  resetState();
  debouncedHealthGoal();
});

getHealthGoals()
</script>

<style lang="scss" scoped>
:deep(.add-new-circle-icon) {
  margin-right: 3px;
}

.left-filter-text {
  :deep(.v-btn) {
    justify-content: left;
  }
}
</style>